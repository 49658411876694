#colorlib-aside {
    padding-top: 3em;
    padding-bottom: 40px;
    float: left;
    width: 300px;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    z-index: 1001;
    background: #f2f3f7;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#colorlib-aside::-webkit-scrollbar {
    display: none;
}

#colorlib-aside .colorlib-footer {
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 20px;
    width: 100%;
    position: absolute;
    bottom: 0;
}
h1{
    font-family: Raleway;
}
